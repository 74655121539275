import * as React from "react"
import Layout from '../../components/layout'
import { Link } from "gatsby"

export default function Favorites() {
  return(
    <Layout>

      <Link to="/" state={{ item: 'writing' }} className="flex space-x-2 items-center hover:underline mb-7">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
        </svg>
        <div >
          back to all reading
        </div>
      </Link>

      <div className="text-2xl font-bold mb-6">
        my favorite books
      </div>

      <div className="space-y-6 md:space-y-0 md:grid md:grid-cols-3 md:gap-x-14">

        <div className="space-y-2">
          <div className="text-lg font-bold mb-4">
            life-changing
          </div>
          <div>
            <a href="https://www.goodreads.com/book/show/54785515-four-thousand-weeks" className="text-blue-600 underline">Four Thousand Weeks: Time Management for Mortals</a> (read 2021)
          </div>
          <div>
            <a href="https://www.goodreads.com/book/show/37506348-winners-take-all" className="text-blue-600 underline">Winners Take All: The Elite Charade of Changing the World</a> (read 2020)
          </div>
          <div>
            <a href="https://www.goodreads.com/book/show/25898216-stamped-from-the-beginning" className="text-blue-600 underline">Stamped from the Beginning: The Definitive History of Racist Ideas in America</a> (read 2020)
          </div>
        </div>
        <div className="space-y-2">
          <div className="text-lg font-bold mb-4">
            will keep rereading
          </div>
          <div>
            <a href="https://www.goodreads.com/book/show/35960060-picture-us-in-the-light" className="text-blue-600 underline">Picture Us in the Light</a> (read 2023)
          </div>
          <div>
            <a href="https://www.goodreads.com/book/show/36348525-severance" className="text-blue-600 underline">Severance</a> (read 2022)
          </div>
          <div>
            <a href="https://www.goodreads.com/book/show/41963431-the-smart-enough-city" className="text-blue-600 underline">The Smart Enough City: Putting Technology in Its Place to Reclaim Our Urban Future</a> (read 2022)
          </div>
          <div>
            <a href="https://www.goodreads.com/book/show/54503590-in-the-shadow-of-the-ivory-tower" className="text-blue-600 underline">In the Shadow of the Ivory Tower: How Universities Are Plundering Our Cities</a> (read 2022)
          </div>
          <div>
            <a href="https://www.goodreads.com/book/show/41952241-goodbye-again" className="text-blue-600 underline">Goodbye, Again: Essays, Reflections, and Illustrations</a> (read 2021)
          </div>
          <div>
            <a href="https://www.goodreads.com/book/show/43261189-the-years-that-matter-most" className="text-blue-600 underline">The Years That Matter Most: How College Makes or Breaks Us (Revised title: The Inequality Machine)</a> (read 2021)
          </div>
        </div>
       
        <div className="space-y-2">
          <div className="text-lg font-bold mb-4">
            honorable mentions
          </div>
          <div>
            <a href="https://www.goodreads.com/book/show/51804506-mountain-movers" className="text-blue-600 underline"> Mountain Movers: Student Activism & The Emergence of Asian American Studies</a> (read 2022)
          </div>
          <div>
            <a href="https://www.goodreads.com/book/show/57359691-invisible-child" className="text-blue-600 underline">Invisible Child: Poverty, Survival & Hope in an American City</a> (read 2022)
          </div>
          <div>
            <a href="https://www.goodreads.com/book/show/46064081-after-the-last-border" className="text-blue-600 underline">After the Last Border: Two Families and the Story of Refuge in America</a> (read 2021)
          </div>
          <div>
            <a href="https://www.goodreads.com/book/show/41880092-the-undocumented-americans" className="text-blue-600 underline">The Undocumented Americans</a> (read 2021)
          </div>
          <div>
            <a href="https://www.goodreads.com/book/show/28162897-the-latinos-of-asia" className="text-blue-600 underline">The Latinos of Asia: How Filipino Americans Break the Rules of Race</a> (read 2021)
          </div>
        </div>

      </div>

      <div className="h-80">
      </div>
      
    </Layout>
  )
}