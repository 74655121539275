import React, {useState} from "react"
import { Transition } from '@headlessui/react'
import resume from '../files/JuliaCamacho_Resume.pdf'
import { Link } from "gatsby"

const navigation = [
  { name: 'about', href: 'about' },
  { name: 'projects', href: 'projects' },
  { name: 'research', href: 'research' },
  { name: 'writing', href: 'writing'}
]

export default function Layout({ children }) {

  const [menuIsOpen, setMenuIsOpen] = useState(false)

  return (
    <div className="font-sans">

      <header className="mb-10 lg:pt-4">
        <nav className="mx-auto px-8 sm:px-6 lg:px-60" aria-label="Top">
          <div className="w-full py-6 flex items-center justify-between border-b border-slate-200 lg:border-none">
            <div className="flex items-center">
              <Link to="/">
                <span className="sr-only">Julia Camacho</span>
                <h1 className="sm:text-3xl md:text-3xl font-bold text-headerPink">julia christina camacho</h1>
              </Link>
            </div>
            <div>
              <div className="hidden lg:ml-10 lg:space-x-8 lg:block lg:flex">
                {navigation.map((link) => (
                  <div>
                    <Link to="/" state={{ item: link.href }}>
                      <button className="text-base font-semibold text-slate-900 hover:underline">
                        {link.name}
                      </button>
                    </Link>
                  </div>
                ))}
                <a href={resume} className="text-base font-semibold text-slate-900 hover:underline">
                  resume
                </a>
              </div>
            </div>
            <div className="-mr-2 flex items-center lg:hidden">
              <button type="button" onClick={() => setMenuIsOpen(!menuIsOpen)} className="inline-flex items-center justify-center p-2 rounded-md text-slate-900 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-slate-500 transition duration-150 ease-in-out" id="main-menu" aria-label="Main menu" aria-haspopup="true">
                <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              </button>
            </div>
          </div>
          
          <Transition
            show={menuIsOpen}
            enter="duration-50 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="z-20 absolute top-0 inset-x-0 p-2 transition transform origin-top-right lg-hero:hidden">
              <div className="rounded-lg shadow-md">
                <div className="rounded-lg bg-white shadow-xs overflow-hidden" role="menu" aria-orientation="vertical" aria-labelledby="main-menu">
                  <div className="px-5 pt-4 flex items-start justify-between">
                  <div className="px-2 pt-2 pb-6">
                    {navigation.map((link) => (
                      <div>
                        <Link to="/" state={{ item: link.href }}>
                          <button className="text-base font-semibold hover:underline">
                            {link.name}
                          </button>
                        </Link>
                      </div>
                    ))}
                    <a href={resume} className="text-base font-semibold hover:underline">
                      resume
                    </a>
                  </div>
                    <div className="-mr-2">
                        <button type="button" onClick={() => setMenuIsOpen(!menuIsOpen)} className="inline-flex items-center justify-center p-2 rounded-md text-slate-400 hover:text-slate-500 hover:bg-slate-100 focus:outline-none focus:bg-gray-100 focus:text-slate-500 transition duration-150 ease-in-out" aria-label="Close menu">
                        <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                        </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          
        </nav>
      </header>

      <div className="mx-auto px-8 sm:px-6 lg:px-60">
        {children}
      </div>

      <div className="mt-12 border-t border-slate-100 py-8">
        <p className="text-xs sm:text-sm md:text-base text-gray-400 text-center">website built using <a href="https://www.gatsbyjs.com/" className="underline">Gatsby</a> & last updated August 2024.</p>
      </div>

    </div>
  )
}